import React, { Component } from 'react';
import './ModalForceWin.scss';
import { toast } from 'react-toastify';
import { customersListAutocomplete } from '../services/UserServices';
import { getBestOffers, assignForceWin, removeWin } from '../services/LotServices';
import { connect } from 'react-redux';
import { logoutAction } from '../redux/actions/UserActions';
import Select from 'react-select';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Table, Input } from 'reactstrap';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ModalCustomer from './ModalCustomer/ModalCustomer';
import { confirmAlert } from 'react-confirm-alert';
import Loading from './Loading';

class ModalForceWin extends Component {
    state = {
        customers: [],
        customerSelect: null,
        bestOffers: [],
        lot: {
            id_lot: '',
            title: '',
            quantity: 0,
        },
        priceForceWin: null,
        priceForceWinTotal: null,
        showModalCustomer: false,
        originalQuantity: 0,
    }

    componentDidMount = async () => {
        toast.configure();
        await this.fetchCustomers(this.props.lot.bestUserIdOffer, this.props.lot.bestUserNameOffer);
        this.fetchBestOffers();
    }

    fetchCustomers = async (idUser, name) => {
        try {
            let response = await customersListAutocomplete({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                customers: response.data.data.customers
            });
            if (idUser !== null) {
                let customer = null;
                for (let _customer of response.data.data.customers) {
                    if (_customer.id_user === idUser) {
                        customer = _customer;
                    }
                }
                await this.setState({
                    customerSelect: { value: customer, label: `${name} (${customer.phone})` },
                    priceForceWin: this.props.lot.offer !== 0 ? this.props.lot.offer : null
                });
            }
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchBestOffers = async () => {
        try {
            let response = await getBestOffers({
                lot: this.props.lot.id,
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                bestOffers: response.data.data.offers,
                lot: response.data.data.lot,
                originalQuantity: response.data.data.lot.quantity,
                priceForceWinTotal: this.state.priceForceWin ? this.state.priceForceWin * response.data.data.lot.quantity : null
            });
        } catch (error) {

        }
    }

    assignForceWin = async () => {
        await this.setState({ loading: true });
        if (this.state.lot?.quantity > this.state.originalQuantity) {
            await this.setState({ loading: false });
            toast.error(`La cantidad no puede ser mayor a la original.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        try {
            let response = await assignForceWin({
                lot: this.props.lot.id,
                token: this.props.sessionProps.account.token,
                idUser: this.state.customerSelect.value.id_user,
                price: this.state.priceForceWin,
                quantity: this.state.lot?.quantity || null
            });
            this.close();
            toast(`Comprador asignado correctamente.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            toast.error(`Ocurrió un error al procesar la solicitud, reintentar.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    removeWin = () => {
        confirmAlert({
            title: 'Remover comprador',
            message: `¿Esta seguro que desea remover el comprador del lote?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._removeWin()
                    }
                }
            ]
        });
    }

    _removeWin = async () => {
        await this.setState({ loading: true });
        try {
            let response = await removeWin({
                lot: this.props.lot.id,
                token: this.props.sessionProps.account.token
            });
            this.close();
            toast(`Comprador removido correctamente.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            toast.error(`Ocurrió un error al procesar la solicitud, reintentar.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    close = () => {
        this.props.close();
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'priceForceWinTotal') {
            let valueTmp = !value ? null : value;
            this.setState({
                priceForceWinTotal: valueTmp,
                priceForceWin: valueTmp ? valueTmp / (this.state.lot?.quantity || 1) : null
            });
            return;
        }
        if (name === 'priceForceWin') {
            let valueTmp = !value ? null : value;
            this.setState({
                priceForceWin: valueTmp ? valueTmp : null,
                priceForceWinTotal: valueTmp ? valueTmp * (this.state.lot?.quantity || 1) : null
            });
            return;
        }
        if (name === 'quantity') {
            this.setState({
                lot: {
                    ...this.state.lot,
                    quantity: value
                },
                priceForceWin: this.state.priceForceWinTotal ? this.state.priceForceWinTotal / (value || 1) : null
            });
            return;
        }
        this.setState({
            [name]: value
        });
    }

    closeModal = async (customer) => {
        await this.setState({
            showModalCustomer: false
        });
        this.fetchCustomers(customer.id_user, customer.name);
    }

    render() {
        return (
            <div className="container-modal">
                {
                    this.state.showModalCustomer &&
                    <ModalCustomer customerId={this.state.customerSelect !== null ? this.state.customerSelect.value.id : 0}
                        {...this.props} close={(customer) => this.closeModal(customer)} />
                }
                {this.state.loading && <Loading />}
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-10">
                                                <h5 style={{ marginBottom: 0 }}>Lote {this.props.lot.id_lot}, {this.props.lot.title}</h5>
                                            </div>
                                            <div className="col-2" onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                                <h5 style={{ marginBottom: 0 }}>X</h5>
                                            </div>
                                        </div>
                                        <hr style={{ marginTop: '0.5rem' }} />
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Comprador</Label>
                                            <Col md={10} style={{ zIndex: 9, display: 'flex' }}>
                                                <Select
                                                    name="features"
                                                    options={this.state.customers.map(item => ({ value: item, label: `${item.name} (${item.phone})` }))}
                                                    className="select2 select2-multiple w-100"
                                                    placeholder="Seleccionar comprador a buscar"
                                                    value={this.state.customerSelect}
                                                    onChange={(e) => this.setState({ customerSelect: e })}
                                                />
                                                <Link onClick={() => this.setState({ showModalCustomer: true })} className="text-success">
                                                    {
                                                        this.state.customerSelect !== null ?
                                                            <i className="mdi mdi-account-edit font-size-22" style={{ marginLeft: '5px' }}></i>
                                                            : <i className="mdi mdi-account-plus font-size-22" style={{ marginLeft: '5px' }}></i>
                                                    }
                                                </Link>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Cantidad</Label>
                                            <Col md={10}>
                                                <Input name="quantity" type="number" className="form-control"
                                                    placeholder="Ingrese cantidad"
                                                    value={this.state.lot.quantity} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Precio total</Label>
                                            <Col md={10}>
                                                <Input name="priceForceWinTotal" type="number" className="form-control"
                                                    placeholder="Ingrese Precio subastado total"
                                                    value={this.state.priceForceWinTotal} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Precio unidad</Label>
                                            <Col md={10}>
                                                <Input name="priceForceWin" type="number" className="form-control"
                                                    placeholder={`${this.props.isOnline ? 'Ingrese Precio subastado por unidad' :
                                                        'Ingrese Precio subastado, si es vacío se tomará la mejor oferta'}`}
                                                    value={this.state.priceForceWin} onChange={this.handleChange} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '1rem' }}>
                                            <div>
                                                <Button onClick={() => this.close()} type="reset" color="secondary">
                                                    Cerrar
                                                </Button>
                                                <Button onClick={() => this.removeWin()} color="danger" className="ml-2">
                                                    Eliminar
                                                </Button>
                                                <Button onClick={() => this.assignForceWin()} color="primary" className="ml-2">
                                                    Asignar
                                                </Button>
                                            </div>
                                        </FormGroup>
                                        {
                                            this.state.bestOffers.length > 0 &&
                                            <React.Fragment>
                                                <div style={{ marginTop: '1rem' }}>
                                                    <h5>Mejores ofertas del lote {this.state.lot.id_lot}, {this.state.lot.remision?.title} </h5>
                                                </div>
                                                <div className="table-responsive" style={{ marginTop: '1rem' }}>
                                                    <Table className="mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Posición</th>
                                                                <th>Cliente</th>
                                                                <th>Oferta</th>
                                                                <th>Fecha</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.bestOffers.map(item =>
                                                                    <tr>
                                                                        <td>{item.position}</td>
                                                                        <td>{item.customer.name}</td>
                                                                        <td>{this.props.money} {new Intl.NumberFormat("de-DE").format(item.offer)}</td>
                                                                        <td>{`${moment(item.created_at).format('dddd, D. MMMM HH:mm')} hs`}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalForceWin);